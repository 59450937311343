import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularJSResourceService } from './angular-js-resource.service';
import { LegacyApiService } from './legacy-api.service';
import { TalentPlatformSettingsService } from './talent-platform-settings.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private get authService(): any {
    return this.ngService?.getResource('authen');
  }
  constructor(private tpsService: TalentPlatformSettingsService, private ngService: AngularJSResourceService, private router: Router, private legacyApiService: LegacyApiService) {}

  isAuth(): boolean {
    return !!localStorage.getItem('profile-accessToken');
  }

  isBtpEnabled(): boolean {
    return this.tpsService.getConfigFlag('EnableTalentPlatform');
  }

  logout(customerCode: string, locationCode: any): void {
    const btpIsEnabled = this.isBtpEnabled();
    this.authService?.logout();
    if (btpIsEnabled) {
      localStorage.clear();
      sessionStorage.clear();
      if (customerCode && locationCode) {
        this.router.navigate([`${customerCode}/${locationCode}/logout`]);
      }
    } else {
      this.legacyApiService.logOutOfLegacyApp.subscribe((res) => {
        localStorage.clear();
        sessionStorage.clear();
        this.router.navigate([`${customerCode}/${locationCode}/logout`]);
      });
    }
  }
}
