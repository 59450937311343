import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router } from '@angular/router';
import { filter, interval, map, Observable, Subscription, take, takeWhile } from 'rxjs';
import { BannerConfig } from 'src/profile-ng/app/shared/models/banner-config';
import { Helper } from '../../../shared/classes/helper';
import { LayoutService } from '../../services/layout.service';
import { TalentPlatformSettingsService } from '../../services/talent-platform-settings.service';
import { TranslationService } from '../../services/translation.service';

@Directive()
export abstract class BaseContainerComponent implements OnInit, OnDestroy {
  protected abstract layoutService: LayoutService;
  protected abstract router: Router;
  protected abstract activatedRoute: ActivatedRoute;
  protected abstract translationService: TranslationService;
  protected abstract tpsService: TalentPlatformSettingsService;

  urlFragment?: string;
  urlQueryParams?: Params;
  urlParams?: Params;

  navigationEnd$: Observable<NavigationEnd>;
  urlFragment$: Observable<string>;
  urlQueryParams$: Observable<Params>;
  urlParams$: Observable<Params>;

  subscriptions: Subscription[] = [];

  get isBtpEnabled(): boolean {
    return this.tpsService.getConfigFlag('EnableTalentPlatform');
  }

  get isLegacyFormsEnabled(): boolean {
    // defaulting as true until more Talent Platform work is done
    return true;
  }

  get homePhoneRequired(): boolean {
    return this.tpsService.getConfigFlag('require_home_phone');
  }

  ngOnInit() {
    this.connectObservablesFromAbstractServices();
    this.initializeSubs();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  connectObservablesFromAbstractServices(): void {
    this.navigationEnd$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)) as Observable<NavigationEnd>;
    this.urlFragment$ = this.activatedRoute.fragment.pipe(
      map((fragment) => {
        if (fragment == null) {
          return undefined;
        } else {
          return fragment;
        }
      }),
    );
    this.urlQueryParams$ = this.activatedRoute.queryParams;
    this.urlParams$ = this.activatedRoute.params;
  }

  initializeSubs(): void {
    this.subscriptions.push(
      ...[
        this.urlFragment$.subscribe((fragment) => (this.urlFragment = fragment)),
        this.urlQueryParams$.subscribe((queryParams) => (this.urlQueryParams = queryParams)),
        this.urlParams$.subscribe((params) => (this.urlParams = params)),
      ],
    );
  }

  navigateToRoute(routerLink: string[], isBtpRoute: boolean, extras: NavigationExtras): void {
    if (isBtpRoute) {
      this.router.navigate(routerLink, {
        ...extras,
        relativeTo: this.activatedRoute,
      });
    } else {
      this.router.navigate(routerLink, {
        ...extras,
      });
    }
  }

  scrollToFragment(fragment: string | undefined, config?: ScrollIntoViewOptions & { focus?: boolean }): void {
    if (fragment != null) {
      let ele: HTMLElement | null;
      interval(10)
        .pipe(
          take(200),
          takeWhile(() => !ele),
        )
        .subscribe(() => {
          ele = document.getElementById(fragment);
          if (ele) {
            ele.scrollIntoView(config);
            if (config.focus) {
              if (ele.tagName === 'NGX-INTL-TEL-INPUT') {
                const inputEle = ele.querySelector('input');
                inputEle.focus();
              } else {
                ele.focus();
              }
            }
          }
        });
    }
  }

  scrollToTop(): void {
    this.layoutService.scrollToTop();
  }

  handleRemoveBanner(bannerId: string): void {
    this.layoutService.removeBanner(bannerId);
  }

  checkForValidIntlPhoneNumber(phoneNumber: string, bannerConfig: BannerConfig): boolean {
    const phoneIsValid = Helper.validateIntlPhoneNumber(phoneNumber);

    if (!phoneIsValid) {
      this.layoutService.addBanner(bannerConfig);
      return false;
    }

    return true;
  }
}
