import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, fromEvent, map, Observable, of } from 'rxjs';
import { LanguageDetail } from '../../shared/models/language-detail.model';
import { CompanyService } from './../../shared/services/company.service';
import { TalentPlatformSettingsService } from './talent-platform-settings.service';
import { environment } from 'src/profile-ng/environments/environment';
import { SessionService } from './session.service';

/* ----------------------------------- // ----------------------------------- */

// TODO: BTP -- Flesh out accurately and move file
interface Translation {
  customer_id: number;
  language: string;
  translation_id: number;
  translation_key: string;
  translation_value: string;
  type: string;
}

interface RawTranslation {
  customerId: number;
  language: string;
  translationId: number;
  translationKey: string;
  translationValue: string;
  type: string;
}

/* ---------------------- localStorage.setItem override --------------------- */

const origSetItem = window.localStorage.setItem;
window.localStorage.setItem = function setItem(key, value) {
  // retrieve old value before we store the new one
  let oldValue = window.localStorage.getItem(key);
  // store in localStorage
  const result = origSetItem.apply(this, [key, value]);
  // manually fire a "storage" event so this window is alerted. On its own,
  // localStorage.setItem() only fires a "storage" event for other tabs.
  const e = new StorageEvent('storage', {
    storageArea: window.localStorage,
    key,
    oldValue,
    newValue: value,
    url: window.location.href,
  });
  window.dispatchEvent(e);
  return result;
};

/* ----------------------------------- // ----------------------------------- */

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private _activeLanguage: BehaviorSubject<string> = new BehaviorSubject(null);
  public activeLanguage$: Observable<string> = this._activeLanguage.asObservable();
  public languageOptions$: Observable<any>;

  private translationMap: Map<string, string>;

  private get translationsFromStorage(): Translation[] {
    const storedTranslations: { translations: Translation[] } = JSON.parse(localStorage.getItem('profile_stored_translations'));
    return storedTranslations?.translations;
  }

  private get languageFromStorage(): string {
    return localStorage.getItem('profile-language') ?? 'English';
  }

  private languageFromStorageEvent$ = fromEvent<StorageEvent>(window, 'storage').pipe(
    filter((event) => event.storageArea === localStorage),
    filter((event) => event.key === 'profile-language'),
    map((event) => event.newValue),
  );

  constructor(private companyService: CompanyService, private talentPlatformSettingsService: TalentPlatformSettingsService, private http: HttpClient, private sessionService: SessionService) {
    this.mapTranslations(this.languageFromStorage);
    this.languageFromStorageEvent$.subscribe((lang) => {
      if (lang !== this._activeLanguage.getValue()) this.mapTranslations(lang);
    });
    this.languageOptions$ = this.companyService.getLanguages().pipe(map((langs) => this.setupLanguageOptions(langs)));
  }

  // initialize 1:1 translation Map
  mapTranslations(language: string, type: string = 'PROFILE') {
    if(this.translationsFromStorage?.some(trans => trans.language === language && trans.type === type)){
      const reducedTranslations = this.translationsFromStorage
      ?.filter((trans) => trans.language === language && trans.type === type)
      .reduce((acc, val) => {
        acc.push([val.translation_key, val.translation_value]);
        return acc;
      }, []);
      this.translationMap = new Map(reducedTranslations);
      this._activeLanguage.next(language);

    }else{
      this.getCompanyTranslations().subscribe({
        next: (translation) => {
          const reducedTranslations = translation
          ?.filter((trans) => trans.language === language && trans.type === type)
          .reduce((acc, val) =>{
            acc.push([val.translationKey, val.translationValue]);
            return acc;
          }, []);
          this.translationMap = new Map(reducedTranslations);
          this._activeLanguage.next(language);
        }
      });
    }
  }

   getCompanyTranslations(): Observable<RawTranslation[]> {
    const url = `${environment.applicant_core_api_host}/api/v1/customerContext/${this.sessionService.profileCustomerId}/translations`;
    return this.http.get<{translations: RawTranslation[]}>(url).pipe(
      map(res => res.translations)
    );
   }

  setupLanguageOptions(langs: LanguageDetail[]) {
    // Set English as default if no langs
    let languages = langs.length > 0 ? langs : [{ language: 'English', displayText: 'English', isDefault: true }];
    if (this.talentPlatformSettingsService.getConfigFlag('enable_language_support')) {
      return languages
        .sort((a, b) => (a.isDefault < b.isDefault ? 1 : -1))
        .map((lang) => {
          const label = lang.displayText?.length ? lang.displayText : lang.language;
          return { value: lang.language, label: label };
        });
    } else {
      let defaultLang = languages.find((lang) => lang.isDefault);
      const label = defaultLang.displayText?.length ? defaultLang.displayText : defaultLang.language;
      return [{ value: defaultLang.language, label: label }];
    }
  }

  // get single translation value
  getTranslation(key: string): string {
    return this.translationMap.get(key);
  }

  getAsyncTranslation(transKey: string | Observable<string>, transValues?: string[] | number[] | Observable<string[] | number[]>, defaultValue?: string, translateValues = false): Observable<string> {
    if (!transKey) return;
    const key$ = typeof transKey === 'string' ? of(transKey) : transKey;
    const values$ = !transValues ? of([]) : transValues instanceof Array ? of(transValues) : transValues;
    return combineLatest([this.activeLanguage$.pipe(distinctUntilChanged()), key$, values$]).pipe(
      filter(([lang, key, values]) => !!lang && !!key),
      map(([lang, key, values]) => {
        if (values?.length) {
          return this.translateTemplate(key, values, translateValues) ?? defaultValue;
        }
        else {
          // testing to see if there is an issue with loading these translations
          if(key == 'terms_of_use_subtitle' || key == 'terms_of_use_title'){
            console.log('translation value - ', this.getTranslation(key));
          }
          return this.getTranslation(key) ?? defaultValue;
        }
      }),
    );
  }

  // set language (changes translation values)
  setProfileLanguage(language: string) {
    localStorage.setItem('profile-language', language);
  }

  // string interpolation of translation values stored with "{{}}" variable syntax
  // e.g. template: 'Hello {{}} {{}}!', varValues: ['firstName', 'lastName']
  translateTemplate(templateKey: string, variableValues: (string | number)[], translateValues = false): string {
    let template = this.translationMap.get(templateKey);
    if (!template) return '';

    for (let i = 0; i < variableValues.length; i++) {
      let varValue = variableValues[i];
      // check if varValue is a translation itself
      if (typeof varValue === 'string') {
        let translationKey = varValue.split(' ').join('_').toLowerCase();
        if (translateValues && this.translationMap.has(translationKey)) varValue = this.translationMap.get(translationKey);
      }
      template = template.replace('{{}}', String(varValue));
    }
    return template;
  }
}
