// This file is replaced during build by using the `fileReplacements` array in angular.json config file.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.

export const environment = {
  production: window["NG_CONFIG"]["production"],
  php_host: window["NG_CONFIG"]["php_host"],
  applicant_core_api_host: window["NG_CONFIG"]["applicant_core_api_host"],
  customer_core_api_host: window["NG_CONFIG"]["customer_core_api_host"],
  i9_core_api_host: window["NG_CONFIG"]["i9_core_api_host"],
  poll_interval: window["NG_CONFIG"]["poll_interval"],
  heartbeat_interval: window["NG_CONFIG"]["heartbeat_interval"],
  scroll_limit: window["NG_CONFIG"]["scroll_limit"],
  num_jobs_to_show: window["NG_CONFIG"]["num_jobs_to_show"],
  apps_to_load: window["NG_CONFIG"]["apps_to_load"],

  // hellosign
  hellosign_client_id: window["NG_CONFIG"]["hellosign_client_id"],
  hellosign_client_id_insert_everywhere: window["NG_CONFIG"]["hellosign_client_id_insert_everywhere"],
  hellosign_skip_domain_verification: window["NG_CONFIG"]["hellosign_skip_domain_verification"],

  // auth0
  auth0_client_url: window["NG_CONFIG"]["auth0_client_url"],
  auth0_client_id: window["NG_CONFIG"]["auth0_client_id"],
  auth0_custom_url: window["NG_CONFIG"]["auth0_custom_url"],

  fullstory_enabled: window["NG_CONFIG"]["fullstory_enabled"],
  newrelic_app_id: window["NG_CONFIG"]["newrelic_app_id"],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
